import './App.css';
import Task from "./Task";
import {useEffect, useState} from "react";

function App() {

  const [tasks,setTasks] = useState([]);
  const [info,setInfo] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search)
  const buildid = queryParameters.get("buildid")

  useEffect(()=>{
    localStorage.clear()
    setTasks([])
    const fetchBuild = async () => {
      const response = await fetch("https://api.poelevellingplanner.com/get/planner/builds?buildid=" + buildid)
      const info = (await response.json());
      setInfo(info)
    }
    
    fetchBuild();
    const fetchGemReward = async () => {
      const response = await fetch("https://api.poelevellingplanner.com/get/planner/build/gemreward?buildid=" + buildid)
      const gemreward = (await response.json());
      const response2 = await fetch("https://api.poelevellingplanner.com/get/planner/build/gemvendor?buildid=" + buildid)
      const gemvendor = (await response2.json());
      const act1 = []
      const act1Color = []
      const act1quest = []
      const act1Vendor = []
      const act1VendorColor = []
      const act1Cost = []
      gemreward.map(function(line){
          if (line.questid == 111) {act1[110] = line.gemName;act1Color[110] = line.Color;act1quest[110] = line.name}
          if (line.questid == 112) {act1[120] = line.gemName;act1Color[120] = line.Color;act1quest[120] = line.name}
          if (line.questid == 113) {act1[130] = line.gemName;act1Color[130] = line.Color;act1quest[130] = line.name}
          if (line.questid == 1135) {act1[131] = line.gemName;act1Color[131] = line.Color;act1quest[131] = line.name}
          if (line.questid == 114) {act1[140] = line.gemName;act1Color[140] = line.Color;act1quest[140] = line.name}
          if (line.questid == 1145) {act1[141] = line.gemName;act1Color[141] = line.Color;act1quest[141] = line.name}
          if (line.questid == 115) {act1[150] = line.gemName;act1Color[150] = line.Color;act1quest[150] = line.name}
          if (line.questid == 211) {act1[210] = line.gemName;act1Color[210] = line.Color;act1quest[210] = line.name}
          if (line.questid == 212) {act1[220] = line.gemName; act1Color[220] = line.Color;act1quest[220] = line.name}
          if (line.questid == 311) {act1[310] = line.gemName; act1Color[310] = line.Color;act1quest[310] = line.name}
          if (line.questid == 312) {act1[320] = line.gemName; act1Color[320] = line.Color;act1quest[320] = line.name}
          if (line.questid == 313) {act1[330] = line.gemName; act1Color[330] = line.Color;act1quest[330] = line.name}
          if (line.questid == 411) {act1[410] = line.gemName; act1Color[410] = line.Color;act1quest[410] = line.name}
          if (line.questid == 412) {act1[420] = line.gemName; act1Color[420] = line.Color;act1quest[420] = line.name}
          
      })
      gemvendor.map(function(line){
         if (line.gemid != 0) {
          if(line.questid == 111){let x = 111; act1Vendor[x] = line.gemName;act1VendorColor[x] = line.Color; act1Cost[x] = line.Currency; x++}
          if(line.questid == 112){let y = 121; act1Vendor[y] = line.gemName;act1VendorColor[y] = line.Color; act1Cost[y] = line.Currency;  y++}
          if(line.questid == 113){let z = 132; act1Vendor[z] = line.gemName;act1VendorColor[z] = line.Color; act1Cost[z] = line.Currency;  z++}
          if(line.questid == 114){let a = 142; act1Vendor[a] = line.gemName;act1VendorColor[a] = line.Color; act1Cost[a] = line.Currency;  a++}
          if(line.questid == 115){let b = 151; act1Vendor[b] = line.gemName;act1VendorColor[b] = line.Color; act1Cost[b] = line.Currency;  b++}
          if(line.questid == 211){let c = 211; act1Vendor[c] = line.gemName;act1VendorColor[c] = line.Color; act1Cost[c] = line.Currency;  c++}
          if(line.questid == 212){let d = 221; act1Vendor[d] = line.gemName;act1VendorColor[d] = line.Color; act1Cost[d] = line.Currency;  d++}
          if(line.questid == 311){let e = 311; act1Vendor[e] = line.gemName;act1VendorColor[e] = line.Color; act1Cost[e] = line.Currency;  e++}
          if(line.questid == 312){let f = 321; act1Vendor[f] = line.gemName;act1VendorColor[f] = line.Color; act1Cost[f] = line.Currency;  f++}
          if(line.questid == 313){let g = 331; act1Vendor[g] = line.gemName;act1VendorColor[g] = line.Color; act1Cost[g] = line.Currency;  g++}
          if(line.questid == 411){let h = 411; act1Vendor[h] = line.gemName;act1VendorColor[h] = line.Color; act1Cost[h] = line.Currency;  h++}
          if(line.questid == 412){let k = 421; act1Vendor[k] = line.gemName;act1VendorColor[k] = line.Color; act1Cost[k] = line.Currency;  k++}
         }
      })
      addTask("=====ACT 1=====","",true)
      for (let i = 110; i < 160; i++){
        if(i%10==0){
          addTask(act1quest[i],null,null,null,true)
        }
        if(act1[i] && act1[i] != "None"){
          addTask(act1[i],act1Color[i])
        }
        if(i % 10 == 0){
          for (let x = (i + 1);x < (i + 10);x++){
            console.log(x,act1Vendor[x])
            if(act1Vendor[x]){
              addTask(act1Vendor[x],act1VendorColor[x],null,act1Cost[x])
            }
          }
        }
      }
      addTask("=====ACT 2=====","",true)
      for (let i = 210; i < 230; i++){
        if(i%10==0){
          addTask(act1quest[i],null,null,null,true)
        }
        if(act1[i] && act1[i] != "None"){
          addTask(act1[i],act1Color[i])
        }
        if(i % 10 == 0){
          for (let x = (i + 1);x < (i + 10);x++){
            console.log(x,act1Vendor[x])
            if(act1Vendor[x]){
              addTask(act1Vendor[x],act1VendorColor[x],null,act1Cost[x])
            }
          }
        }
      }
      addTask("=====ACT 3=====","",true)
      for (let i = 310; i < 340; i++){
        if(i%10==0){
          addTask(act1quest[i],null,null,null,true)
        }
        if(act1[i] && act1[i] != "None"){
          addTask(act1[i],act1Color[i])
        }
        if(i % 10 == 0){
          for (let x = (i + 1);x < (i + 10);x++){
            console.log(x,act1Vendor[x])
            if(act1Vendor[x]){
              addTask(act1Vendor[x],act1VendorColor[x],null,act1Cost[x])
            }
          }
        }
      }
      addTask("=====ACT 4=====","",true)
      for (let i = 410; i < 430; i++){
        if(i%10==0){
          addTask(act1quest[i],null,null,null,true)
        }
        if(act1[i] && act1[i] != "None"){
          addTask(act1[i],act1Color[i])
        }
        if(i % 10 == 0){
          for (let x = (i + 1);x < (i + 10);x++){
            console.log(x,act1Vendor[x])
            if(act1Vendor[x]){
              addTask(act1Vendor[x],act1VendorColor[x],null,act1Cost[x])
            }
          }
        }
      }
    };
    fetchGemReward(); 
  }, [])


  function addTask(name,color,title,currency,quest) {
    setTasks(prev => {
      return [...prev, {name:name,done:false,color:color,title:title,currency:currency,quest:quest}];
    });
  }

  function removeTask(indexToRemove) {
    setTasks(prev => {
      return prev.filter((taskObject,index) => index !== indexToRemove);
    }); 
  }

  function updateTaskDone(taskIndex, newDone) {
    setTasks(prev => {
      const newTasks = [...prev];
      newTasks[taskIndex].done = newDone;
      return newTasks;
    });
  }
  
  function renameTask(index,newName) {
    setTasks(prev => {
      const newTasks = [...prev];
      newTasks[index].name = newName;
      return newTasks;
    })
  }
  return (
    <main>
      <h2>POE Levelling Planner</h2>
      {info.map((info,index) => (
        <center>Created on: {info.Date.split(" ")[0]}</center>
      ))}
      <br/>
      {info.map((info,index) => (
        <center><img src={"https://poelevellingplanner.com/img/"+info.classname+"/"+info.classname.toLowerCase()+".png"}/></center>
      ))}
      <br/>
      {info.map((info,index) => (
        <center><img src={"https://poelevellingplanner.com/img/"+info.classname+"/"+info.ascName+".png"}/></center>
      ))}
      <br/>
      {tasks.map((task,index) => (
        <Task {...task}
              onRename={newName => renameTask(index,newName)}
              onTrash={() => removeTask(index)}
              onToggle={done => updateTaskDone(index, done)} />
      ))}
    </main>
  );
}

export default App;