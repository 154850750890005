import Checkbox from "./Checkbox";
import {useState} from "react";

export default function Task({name,done,color,onToggle,onTrash,onRename,title,currency,quest}) {
  const [editMode,setEditMode] = useState(false);
  const textStyle = {"color": color}
  console.log(currency)
  return (
    <div className={'task ' + (done?'done':'')}>
        {color && (
             <Checkbox checked={done} onClick={() => onToggle(!done)} />
        )}
      {!editMode && !title && !quest &&(
        <div className="task-name">
          <span style={textStyle}>{name}</span>
        </div>
      )}
      {!editMode && !title && quest &&(
        <div className="task-name">
          <span><b>{name}</b></span>
        </div>
      )}
      {(!editMode && title==true &&(
        <div className="task-name">
            <center><h3>{name}</h3></center>
        </div>
      )

      )}
      {editMode && (
        <form onSubmit={ev => {ev.preventDefault();setEditMode(false);}}>
          <input type="text" value={name}
                 onChange={ev => onRename(ev.target.value)} />
        </form>
      )}
      {!title && currency &&(
         <img src={"https://poelevellingplanner.com/img/currency/"+currency+".png"}/>
      )
      }
       
    </div>
  );
}